import React, { useState } from "react";
import {
  InlineMessage,
  Textarea,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { useFormContext } from "./context";
const RadioGroupGridItem = ({ id, question, formItem }) => {
  const { t } = useTranslation();
  const { values, setValues, errors, setErrors } = useFormContext();
  const [answer, setAnswer] = useState();
  const [reason, setReason] = useState("");
  const { Options } = formItem;

  function handleChangeAnswer(event) {
    setAnswer(event.target.value);
    setErrors({ ...errors, [id]: undefined });
    setReason("");
    setValues({ ...values, [id]: { value: event.target.value, text: null } });
  }

  function trimAnswer(event) {
    const inputText = event.target.value;

    if (inputText.length < 100) return;

    setReason(inputText.slice(0, 100));
    setValues({
      ...values,
      [id]: { value: "false", text: inputText },
    });
  }

  function recordTextAreaInput(event) {
    const inputText = event.target.value;

    if (inputText.length >= 100) return;

    setReason(inputText);
    setErrors({ ...errors, [id]: undefined });
    setValues({
      ...values,
      [id]: { value: "false", text: inputText.trim() },
    });
  }

  return (
    <div className="radiogroup-grid-fields">
      <fieldset>
        <legend className="radiogroup-grid-legend">{question}</legend>
        {Options.map(({ Label, Value }) => (
          <input
            type="radio"
            key={Label}
            name={`question-${id}`}
            id={`${Label}-${id}`}
            value={Value}
            onChange={handleChangeAnswer}
          />
        ))}
        {answer === "false" && (
          <div className="radiogroup-grid-textarea">
            <Textarea
              labelText={t("survey.justify-answer")}
              numberOfRows={2}
              name={`exp-${id}`}
              subtext={t("survey.up-100-characters")}
              onChange={recordTextAreaInput}
              onBlur={trimAnswer}
              value={reason}
            />
          </div>
        )}
      </fieldset>

      {errors[id] && (
        <InlineMessage text={errors[id]} variant="Error" mt="24px" />
      )}
    </div>
  );
};

const RadiogroupGridSection = ({ blocks }) => {
  const radioGroupLabels = blocks[0].FormItems[0].Options.map(
    (option) => option.Label,
  );

  return (
    <div className="radiogroup-grid">
      <div className="radiogroup-grid-labels-row">
        <div className="radiogroup-grid-labels">
          {radioGroupLabels.map((label) => (
            <span key={label}>{label}</span>
          ))}
        </div>
      </div>

      {blocks.map((block, index) => (
        <RadioGroupGridItem
          key={block.QuestionId}
          id={block.QuestionId}
          question={block.Question}
          formItem={block.FormItems[0]}
        />
      ))}
    </div>
  );
};

export { RadiogroupGridSection };
