import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import {
  ConvertDecimalToString,
  setDateFormatForDateTime,
} from "../CommonComp/Functions";
import { useOutletContext } from "react-router-dom";
import OnlinePaymentForm from "./OnlinePaymentForm";
import {
  Button,
  Icon,
  RadioButton,
  RadioButtonGroup,
} from "@nn-design-system/react-component-library";
import "../../Css/OnlinePayment/Online-payment.css";
import {
  bankLinks,
  productCodesOnlinePaymentForShowLinks,
} from "../HR/Utils/Constants";

const OnlinePaymentStep2 = () => {
  const { t } = useTranslation();
  const changeCard = useOutletContext();
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const { contract, payment, isChangeOnlinePaymentMehod } = onlinePaymentStore;
  const [isDifferentTab] = useState(isChangeOnlinePaymentMehod);
  const [radioType, setRadioType] = useState("");
  const [content, setContent] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("beforeunload", () =>
      sessionStorage.setItem("IsChangeCard", changeCard ? changeCard : false),
    );
    if (changeCard !== isDifferentTab) {
      onlinePaymentStore.setIsChangeOnlinePaymentMehod(changeCard);
      onlinePaymentStore.setContract(null);
      navigate(`/online-payment`);
    }
  }, []);

  useEffect(() => {
    if (!payment) {
      navigate(`/online-payment`);
    } else {
      const newPayment = {
        ContractNumber: payment.ContractNumber,
        OutstandingAmount: payment.OutstandingAmount,
        PaymentCodeA: payment.PaymentCodeA,
        InvoiceNumber: payment.InvoiceNumber,
        PaymentMethod: payment.PaymentMethodId,
        ProductCode: payment.ProductCode,
        PaymentFrequency: payment.PaymentFrequencyId,
        ApplicationNumber: payment.ApplicationNumber,
      };
      if (
        contract.ClientPortalOnlinePaymentEnabled &&
        content === "" &&
        !isChangeOnlinePaymentMehod
      ) {
        onlinePaymentStore.sendTicketingWebService(newPayment);
      }

      if (isChangeOnlinePaymentMehod && content === "") {
        onlinePaymentStore.sendTicketingWebServiceForCreditCard(newPayment);
      }
    }
  }, [payment, content]);

  useEffect(() => {
    if (contract) {
      if (contract.ClientPortalOnlinePaymentEnabled) {
        setContent("");
      } else {
        setContent("banking");
      }
    }
  }, [contract]);

  const radioHandler = (e) => {
    setRadioType(e.target.value);
    setContent(e.target.value);
  };

  return (
    <>
      {payment && contract ? (
        <div className="online-payment-form">
          <div className="form-element right-padded">
            <span className="payBold">{"Αρ. ασφαλιστηρίου: "}</span>
            <span>{payment.ContractNumber} </span>
          </div>
          {!isChangeOnlinePaymentMehod && (
            <div className="form-element right-padded">
              <span className="payBold">
                {t("online.payment.payment-amount")}
                {": "}
              </span>
              <span>{ConvertDecimalToString(payment.OutstandingAmount)} </span>
            </div>
          )}
          <div className="form-element right-padded">
            <span className="payBold">
              {!isChangeOnlinePaymentMehod
                ? payment.paymentCodeRF
                  ? `${t("online.payment.code-payment")}: `
                  : `${t("online.payment.code-payment")} A: `
                : `${t("online.payment.card-number")}:`}
            </span>
            <span>
              {!isChangeOnlinePaymentMehod
                ? payment.PaymentCodeA
                : payment.MaskedAccountNumber}{" "}
            </span>
          </div>
          <div className="form-element right-padded">
            {isChangeOnlinePaymentMehod ? (
              <>
                <span className="payBold">{t("global.expiry-date")}: </span>
                <span>
                  {" "}
                  {setDateFormatForDateTime(payment.ExpirationDate)}{" "}
                </span>
              </>
            ) : null}
          </div>
          {!isChangeOnlinePaymentMehod && !payment.paymentCodeRF && (
            <>
              <div className="form-element right-padded">
                <span className="payBold">
                  {t("online.payment.code-payment")}
                  {"Β: "}
                </span>
                <span>{payment.PaymentCodeB} </span>
              </div>
            </>
          )}
          {contract.ClientPortalOnlinePaymentEnabled ? (
            <>
              <div className="filter-contracts">
                <RadioButtonGroup
                  labelText={t("online.payment.payment-methods")}
                  onChange={radioHandler}
                  value={radioType}
                  variant="Small"
                >
                  <RadioButton
                    labelText={t("online.payment.by-debit-credit")}
                    value=""
                  />
                  <RadioButton
                    labelText={t("online.payment.via-web-banking")}
                    value="banking"
                  />
                </RadioButtonGroup>
              </div>
            </>
          ) : null}

          {content === "banking" && !isChangeOnlinePaymentMehod ? (
            <>
              {(payment.PaymentCodeB || payment.paymentCodeRF) &&
              !productCodesOnlinePaymentForShowLinks.some(
                (e) => e === contract.ProductCode,
              ) ? (
                <>
                  {bankLinks.map((bankElement, i) => {
                    return (
                      <div className="form-element right-padded">
                        <div className="chevron-link-new">
                          <a href={bankElement.bankLink} target="_blank">
                            <Icon
                              mr={"10px"}
                              mt={"5px"}
                              mb={"-3px"}
                              name="ChevronRight"
                              variant="Small"
                              color="#EA650D"
                            />
                            <span>{bankElement.bankName}</span>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="form-element right-padded">
                    <div className="chevron-link-new">
                      <a href={bankLinks[0].bankLink} target="_blank">
                        <Icon
                          mr={"10px"}
                          mt={"5px"}
                          mb={"-3px"}
                          name="ChevronRight"
                          variant="Small"
                          color="#EA650D"
                        />
                        <span>{bankLinks[0].bankName}</span>
                      </a>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="iframe-online-payment">
              <OnlinePaymentForm />
              <iframe title="my_iframe_payment" name="my_iframe" />
            </div>
          )}
        </div>
      ) : null}
      <div className="form-element button">
        <Button
          testId="button"
          variant="PrimaryMedium"
          onClick={() => {
            navigate("/online-payment");
          }}
        >
          {t("global.previous")}
        </Button>
      </div>
    </>
  );
};

export default OnlinePaymentStep2;
