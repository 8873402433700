import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  Select,
  Heading,
  Paragraph,
  Table,
} from "@nn-design-system/react-component-library";
import DocumentsStore from "../../Stores/DocumentsStore";
import ContractStore from "../../Stores/ContractStore";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";
import "../../Css/CommonCss/Filters.css";
import "../../Css/documents-table.css";
import { DocumentsTableNN } from "./DocumentsTableNN";
import { DocumentsList } from "./DocumentsList";

const DocumentsTable = () => {
  const { t } = useTranslation();
  const { contractList } = useContext(ContractStore);
  const { documentList, customerDocumentList } = useContext(DocumentsStore);
  const [placeholderValue, setPlaceholderValue] = useState("");
  const [conList, setConList] = useState(null);
  const [docList, setDocList] = useState(null);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 580) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  const columns = [
    {
      field: "ProductName",
      headerName: t("document.contract-name"),
      flex: 1.7,
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      sortable: false,
    },
    {
      field: "DisplayNumber",
      headerName: t("document.insurance-policy-number"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
    },
  ];

  const [dropDownMenuValues, setDropDownMenuValues] = useState([]);

  let newTArray = [];

  useEffect(() => {
    setDocList([...documentList]);
  }, [documentList]);

  useEffect(() => {
    setConList([...contractList.filter((el) => el.StatusId !== "PR")]);
  }, [contractList]);

  const dropDownMenuArray = useCallback(() => {
    let unique_values = [...new Set(documentList.map((p) => p.DocumentType))];
    setDropDownMenuValues(unique_values);
  }, [documentList]);

  useEffect(() => {
    if (docList) {
      dropDownMenuArray();
    }
  }, [docList, dropDownMenuArray]);

  const newArray = useCallback(
    (contractNumber) => {
      let testedArray = docList.filter(
        (x) => x.ContractNumber === contractNumber,
      );
      if (testedArray.length !== 0) {
        return testedArray;
      }
      return testedArray;
    },
    [docList],
  );

  const dropDownHandler = (e) => {
    setPlaceholderValue(e.target.value);
    if (e.target.value !== (null || "all") && documentList.length != 0) {
      const newTestArray = documentList.filter(
        (x) => x.DocumentType === e.target.value,
      );

      setDocList(newTestArray);
    } else {
      setDocList(documentList);
    }
  };

  function dropDownListItem(array, text) {
    let list = array.map((elementDrop) => {
      return {
        text: elementDrop,
        value: elementDrop,
      };
    });
    list.unshift({ text: text, value: "all" });
    return list;
  }

  const hasAtLeastOneValidConListDocumentToShow = useMemo(() => {
    if (conList) {
      for (let index = 0; index < conList.length; index++) {
        let testingArray = newArray(conList[index].Number);
        if (testingArray && testingArray.length > 0) {
          return true;
        }
      }
    }
    return false;
  }, [conList, newArray]);

  const hasSomeDocumentsToShow = useMemo(() => {
    if (
      (customerDocumentList && customerDocumentList.length > 0) ||
      hasAtLeastOneValidConListDocumentToShow
    ) {
      return true;
    }
    return false;
  }, [customerDocumentList, hasAtLeastOneValidConListDocumentToShow]);

  const rows = conList
    ?.map((element, i) => {
      newTArray = newArray(element.Number);
      if (newTArray.length) {
        return {
          id: i,
          ...element,
          newTArray,
        };
      }
    })
    .filter(Boolean);

  return (
    <>
      {conList && hasSomeDocumentsToShow ? (
        <>
          <div className="nn-container">
            <div className="documents-select-container">
              <Select
                labelText=""
                placeholderText={t("document.select-document-type")}
                name="select"
                onChange={dropDownHandler}
                options={dropDownListItem(
                  dropDownMenuValues,
                  t("document.all-documents"),
                )}
                testId="select"
                value={placeholderValue}
              />
            </div>
          </div>
          {hasAtLeastOneValidConListDocumentToShow && (
            <div id="documents-table" className="nn-container">
              <Heading mb="10px" hasMargin={false} variant={"Large"}>
                {t("document.contract-documents")}
              </Heading>

              <Table
                rows={rows}
                density="comfortable"
                columns={columns}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                hasAutoHeight
                localeText={{ noRowsLabel: t("global.no-results-found") }}
                getRowHeight={() => "auto"}
                getDetailPanelContent={(rowData) => {
                  return (
                    <>
                      {isSmallSize ? (
                        rowData.row.newTArray?.map((elemDoc) => (
                          <DocumentsList elemDoc={elemDoc} />
                        ))
                      ) : (
                        <DocumentsTableNN
                          documentList={rowData.row.newTArray}
                        />
                      )}
                    </>
                  );
                }}
                getDetailPanelHeight={() => {
                  return "auto";
                }}
                hideFooter
              ></Table>
            </div>
          )}
          {/* //WHAT IS THAT PABLO?????? */}
          {customerDocumentList && customerDocumentList.length > 0 ? (
            <div id="documents-table" className="nn-container">
              <Heading mb="10px" hasMargin={false} variant={"Large"}>
                {t("document.other-documents")}
              </Heading>
              <DocumentsTableNN documentList={customerDocumentList} />
            </div>
          ) : null}
        </>
      ) : (
        <div
          className="nn-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paragraph mb="20px">{t("document.no-documents")}</Paragraph>
        </div>
      )}
    </>
  );
};
export default observer(DocumentsTable);
