import React, { useState } from "react";
import {
  RadioButtonGroup,
  Textarea,
} from "@nn-design-system/react-component-library";
import { ScoreRadioButton } from "./ScoreRadioButton";
import { useFormContext } from "./context";

const Score = ({ item }) => {
  const [selectedScore, setSelectedScore] = useState();
  const { values, setValues, errors, setErrors } = useFormContext();
  const { questionId } = item;

  function handleSelect(score) {
    setSelectedScore(score);
    setErrors({ ...errors, [questionId]: undefined });

    const currentQuestionReason = values[questionId]?.text;

    setValues({
      ...values,
      [questionId]: { value: score, text: currentQuestionReason || null },
    });
  }

  return (
    <RadioButtonGroup labelText="" onChange={() => {}} variant={"Default"}>
      <div className="score-group">
        {item.Options.map(({ Value, Label }) => (
          <ScoreRadioButton
            key={Value}
            formItemId={item.FormItemId}
            label={Label}
            value={Value}
            isSelected={selectedScore === Value}
            onSelect={handleSelect}
          />
        ))}
      </div>
      <div className="score-minmax-labels">
        <span>{item.MinText}</span>
        <span>{item.MaxText}</span>
      </div>
    </RadioButtonGroup>
  );
};

const ScoreReason = ({ item }) => {
  const { questionId } = item;
  const { values, setValues } = useFormContext();
  const [value, setValue] = useState();

  function handleChange(event) {
    const inputText = event.target.value;
    const currentQuestionValue = values[questionId]?.value;

    setValue(inputText);
    setValues({
      ...values,
      [questionId]: { value: currentQuestionValue, text: inputText },
    });
  }

  return (
    <div className="survey-score-reason">
      <Textarea
        labelText={item.LabelText || ""}
        numberOfRows={item.Rows}
        name={`${item.FormItemId}-reason`}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};

export { Score, ScoreReason };
