import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import NavBar from "../Components/NavBar";
import HeaderComponents from "../Components/Header/HeaderComponents";
import Loader from "../Components/CommonComp/Loader";
import PaymentStore from "../Stores/PaymentStore";
import DocumentsStore from "../Stores/DocumentsStore";
import ContractStore from "../Stores/ContractStore";
import Chat from "../Components/CommonComp/Chat";
import AccountStore from "../Stores/AccountStore";
import Footer from "../Components/Footer/Footer";
import InboxStore from "../Stores/InboxStore";
import Cookies from "../Components/CommonComp/Cookies";
import * as Msal from "msal"; // MSAL v1.0
import ClaimsStore from "../Stores/ClaimsStore";
import CookieStore from "../Stores/CookieStore";
import B2cStore from "../Stores/B2cStore";
import ReactGA from "react-ga4";
import SettingsStore from "../Stores/SettingsStore";
import LoaderStore from "../Stores/LoaderStore";
import ArticleStore from "../Stores/ArticleStore";
import UrlStore from "../Stores/UrlStore";
import SideMenu from "../Components/NavigationMenu/SideMenu";
import NavBarStore from "../Stores/NavBarStore";
import AuthorizedRoutes from "../Routes/AuthorizedRoutes";
import HRStore from "../Stores/HRStore";
import EServices from "../Components/Home/EServices";

const MainContent = (props) => {
  const paymentStore = useContext(PaymentStore);
  const documentStore = useContext(DocumentsStore);
  const contractStore = useContext(ContractStore);
  const {
    contractList,
    hasAtLeastOneGroupPolicy,
    contractsHasContent,
    groupContractsCoveragesHasContent,
  } = contractStore;
  const accountStore = useContext(AccountStore);
  const { accountData, hasMultipleRole } = accountStore;
  const inboxStore = useContext(InboxStore);
  const claimsStore = useContext(ClaimsStore);
  const { groupPoliciesWithEligibilitySubmissionHasContent } = claimsStore;
  const cookieStore = useContext(CookieStore);
  const {
    activeGA,
    cookieHasContent,
    isCustomerSupport,
    hasCookies,
    isCustomerOfNNHellas,
    isCustomerOfNNHellas2,
  } = cookieStore;
  const settingsStore = useContext(SettingsStore);
  const loaderStore = useContext(LoaderStore);
  const b2cStore = useContext(B2cStore);
  const { loginRequest, accessTokenRequest } = b2cStore;
  const articleStore = useContext(ArticleStore);
  const urlStore = useContext(UrlStore);
  const navBarStore = useContext(NavBarStore);
  const { showSideBar } = navBarStore;
  const { isHrRole, setIsHrRole, loadHRContracts, loadHRPayments } =
    useContext(HRStore);
  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";
  const [nelisIds, setNelisIds] = useState([]);
  const navigate = useNavigate();
  const path = useLocation();

  const showMobileViewThreshold = useMemo(() => {
    if (hasAtLeastOneGroupPolicy) {
      return 992;
    }
    return 768;
  }, [hasAtLeastOneGroupPolicy]);

  useEffect(() => {
    if (activeGA) {
      ReactGA.set({ page: path.pathname }); // Update the user's current page
      ReactGA.send({ hitType: "pageview", page: path.pathname });
    }
  }, [activeGA, path]);

  //we add token to local storage with hooks
  // GetAccessToken();

  const getAccessToken = () => {
    props.authService
      .acquireTokenSilent(loginRequest)
      .then(function (loginResponse) {})
      .catch(function (error) {
        //Acquire token silent failure, and send an interactive request
        //console.log(error);
        if (error.errorMessage.indexOf("interaction_required") !== -1) {
          props.authService.acquireTokenRedirect(accessTokenRequest);
        }
      });
  };

  useEffect(() => {
    urlStore.loadURL(process.env.REACT_APP_ENVIRONMENT);
  }, [urlStore]);

  useEffect(() => {
    let companysNelisIds = accountData.HRAdministratorRelationships?.map(
      ({ CurrentPartyNelisId }) => CurrentPartyNelisId,
    );
    if (companysNelisIds) loadHRContracts(companysNelisIds);
    setNelisIds(companysNelisIds);
  }, [accountData.HRAdministratorRelationships]);

  useEffect(() => {
    if (!!nelisIds?.length) {
      loadHRPayments([...nelisIds]);
    }
  }, [nelisIds]);

  useEffect(() => {
    if (isCustomerSupport === false) {
      getAccessToken();
      cookieStore.loadCookies();
      inboxStore.NonBulkMessages();
      accountStore.loadAccountDetails();
      settingsStore.loadPrivateSettings();
      articleStore.loadArticleList();
    } else {
      cookieStore.loadCookies();
      loaderStore.setCustomerSupportLoader(isCustomerSupport);
    }
  }, [isCustomerSupport]);

  useEffect(() => {
    if (
      isCustomerSupport === false &&
      (!checkNN2Users || isCustomerOfNNHellas)
    ) {
      contractStore.loadContracts();
      documentStore.loadContractDocuments();
      documentStore.loadCustomerDocuments();
      paymentStore.loadPayments();
      claimsStore.getMyClaims();
      claimsStore.loadDocumentTypes();
      claimsStore.loadDoctorSpecialities();
    }
  }, [
    isCustomerSupport,
    isCustomerOfNNHellas,
    checkNN2Users,
    contractStore,
    documentStore,
    paymentStore,
    claimsStore,
  ]);

  useEffect(() => {
    if (
      contractsHasContent &&
      contractList.length > 0 &&
      hasAtLeastOneGroupPolicy &&
      !groupPoliciesWithEligibilitySubmissionHasContent
    ) {
      claimsStore.loadGroupPoliciesWithEligibilitySubmission();
    }
  }, [
    contractsHasContent,
    contractList,
    hasAtLeastOneGroupPolicy,
    groupPoliciesWithEligibilitySubmissionHasContent,
    claimsStore,
  ]);

  useEffect(() => {
    if (
      contractsHasContent &&
      contractList.length > 0 &&
      hasAtLeastOneGroupPolicy &&
      !groupContractsCoveragesHasContent
    ) {
      contractStore.loadGroupContractsCoverages();
    }
  }, [
    contractsHasContent,
    contractList,
    groupContractsCoveragesHasContent,
    contractStore,
    hasAtLeastOneGroupPolicy,
  ]);

  useEffect(() => {
    if (cookieHasContent) {
      loaderStore.setMainContentLoader(false);
    } else {
      loaderStore.setMainContentLoader(true);
    }
  }, [cookieHasContent]);

  const [width, setWidth] = useState(window.innerWidth);
  const [showMobileMenu, setShowMobileMenu] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [window]);

  useEffect(() => {
    if (width < showMobileViewThreshold) {
      setShowMobileMenu(true);
    } else {
      setShowMobileMenu(false);
      navBarStore.setShowSideBar(true);
    }
  }, [width, showMobileViewThreshold]);

  useEffect(() => {
    if (isCustomerSupport) {
      navigate(`/cs`);
    }
  }, [isCustomerSupport]);

  useEffect(() => {
    if (!sessionStorage.hasOwnProperty("LoginLog")) {
      accountStore.logLogin();
    }
    if (sessionStorage.hasOwnProperty("isHrRole")) {
      let role = JSON.parse(sessionStorage.getItem("isHrRole"));
      setIsHrRole(role);
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("/oauth/kyc/step1")) {
      navigate("home");
    }
  }, [window]);

  return (
    <Fragment>
      <Loader />
      <div id="inner-root">
        <div className="sticky top">
          <div className="nn-container">
            <header>
              <HeaderComponents showMobileMenu={showMobileMenu} />
              {hasMultipleRole &&
              isHrRole == null ? null : isCustomerSupport ? null : (
                <>
                  {showMobileMenu ? (
                    showSideBar ? null : (
                      <SideMenu />
                    )
                  ) : (
                    <NavBar />
                  )}
                </>
              )}
            </header>
          </div>
        </div>

        <div className="overflowHidden">
          <AuthorizedRoutes
            giveAccessToMyNN={isCustomerOfNNHellas && contractList.length > 0}
            isCustomerOfNNHellas={isCustomerOfNNHellas}
            cookieHasContent={cookieHasContent}
            giveAccess={props.giveAccess}
            isCustomerSupport={isCustomerSupport}
          />
        </div>
      </div>
      {isCustomerSupport ||
      (checkNN2Users &&
        (!isCustomerOfNNHellas || contractList.length <= 0)) ? null : (
        <Chat accountData={accountData} />
      )}
      <footer>
        <div id="dashboard-body">
          {isCustomerOfNNHellas2 ? <EServices /> : null}
        </div>
        <Footer />
      </footer>
      {!isCustomerSupport && hasCookies ? <Cookies /> : null}
    </Fragment>
  );
};

export default observer(MainContent);
