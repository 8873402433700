import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AttentionBlock,
  Button,
  Divider,
  Icon,
  Label,
  Paragraph,
} from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import KycStore from "../../Stores/KycStore";
import { downloadPdfFromBinaryProp } from "../CommonComp/Functions";
import { PrintButtonAsBinary } from "../CommonComp/PrintButtonAsBinary";

const KycStep2 = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kycTermAcceptanceResponse } = useContext(KycStore);

  return (
    <>
      <section className=" ">
        <AttentionBlock testId="test-id" variant="Success">
          <Label
            text={t("kyc.details-updated-successfully")}
            hasMargin={false}
          ></Label>
        </AttentionBlock>
        <Divider mt="32px" />
        <article className="information-container-kyc">
          <Icon name="Information" variant="XL" color="#EA650D" />
          <Paragraph hasMargin={false}>
            {t("kyc.refer-to-my-messages")}
          </Paragraph>
        </article>
        <Divider mt="32px" />
        <article className="kyc-step2-bottom-container">
          <Button variant="PrimaryMedium" onClick={() => navigate("/home")}>
            {t("global.continue")}
          </Button>
          <div>
            <Paragraph hasMargin={false}>{t("kyc.data-archive")}</Paragraph>
            <a
              className="active"
              onClick={() =>
                downloadPdfFromBinaryProp(
                  kycTermAcceptanceResponse.PdfBinary,
                  "KYC-info",
                )
              }
            >
              <Icon name="ArrowDownLine" variant={"Large"} color="#EE7F00" />
            </a>
            <PrintButtonAsBinary
              base64String={kycTermAcceptanceResponse?.PdfBinary}
            />
          </div>
        </article>
      </section>
    </>
  );
});
export default KycStep2;
