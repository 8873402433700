import React, { useContext, useEffect } from "react";
import { Button, Popover } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import KycStore from "../../Stores/KycStore";

const AccountKycButton = ({ onClick, hasVatNumber, isLoading }) => {
  const { t } = useTranslation();
  const { isServiceAlive, resetIsServiceAlive } = useContext(KycStore);

  useEffect(() => {
    if (!isServiceAlive) {
      setTimeout(() => {
        resetIsServiceAlive();
      }, 5000);
    }
  }, [isServiceAlive]);

  return (
    <>
      {hasVatNumber ? (
        <div className="kyc-btn">
          <Button
            variant={"PrimaryMedium"}
            isLoading={isLoading}
            mt="35px"
            onClick={isServiceAlive ? () => onClick() : null}
          >
            {isServiceAlive
              ? t("account.kyc.update-details")
              : t("account.kyc.service-unavailable")}
          </Button>
        </div>
      ) : (
        <div className="claimSubmissionButton">
          <Popover
            variant="Link"
            headingText=" "
            linkVariantProps={{
              text: t("account.kyc.update-details"),
            }}
            placement="bottom-end"
          >
            <div className="claimSubmissionPopover">
              <p className="htmlParagraph">
                {t("account.kyc.vat-condition-msg")}
              </p>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default observer(AccountKycButton);
