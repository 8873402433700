import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@nn-design-system/react-component-library";
import "../../Css/Home/eServices.css";
import SettingsStore from "../../Stores/SettingsStore";

const EServices = () => {
  const { t } = useTranslation();
  const settingsStore = useContext(SettingsStore);
  const { privateSettings } = settingsStore;

  if (privateSettings.EServicesURL) {
    return (
      <div className="eServices">
        <div className="nn-container">
          <span>{t("eservices.click-here")}</span>
          <Button
            ml="10px"
            iconProps={{
              name: "ArrowBox",
              placement: "Right",
            }}
            testId="button"
            variant="PrimarySmall"
          >
            <a
              className="nn-button-normal-white"
              href={privateSettings.EServicesURL}
              target="_blank"
            >
              e-Services
            </a>
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default EServices;
