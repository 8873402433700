import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  Button,
  Label,
  Checkbox,
  AmountInput,
  TextInput,
  DatepickerInput,
  Popover,
  Paragraph,
  NumberInput,
  InlineMessage,
} from "@nn-design-system/react-component-library";
import ClaimsStore from "../../../Stores/ClaimsStore";
import { ExpenseType } from "../../../Consts/Claims";
import FilesUpload from "./FilesUpload";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import moment from "moment";

const PhysicalTherapy = (props) => {
  const { t } = useTranslation();
  const invalidValueText = t("claim.submission.invalid-value");
  const [hasReferral, setHasReferral] = useState("");
  const [hasRefferalError, setHasRefferalError] = useState("");
  const [collaboratingPhysiotherapist, setCollaboratingPhysiotherapist] =
    useState("");
  const [numberOfSessions, setNumberOfSessions] = useState("");
  const [numberOfSessionsError, setNumberOfSessionsError] = useState("");
  const [receiptDate, setReceiptDate] = useState("");
  const [receiptDateError, setReceiptDateError] = useState("");
  const [receiptAmount, setReceiptAmount] = useState("");
  const [receiptAmountError, setReceiptAmountError] = useState("");
  const [otherInsurerContributionAmount, setOtherInsurerContributionAmount] =
    useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [receiptNumberError, setReceiptNumberError] = useState("");

  const [filesTypes, setFilesTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [showFileValidationError, setShowFileValidationError] = useState(false);

  const claimsStore = useContext(ClaimsStore);

  const { basicSubmissionInfoToSubmit, expenseTypes, documentTypes } =
    claimsStore;

  const currentDateValue = useMemo(() => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
  }, []);
  const eventDateValue = useMemo(() => {
    let eventDate = new Date(basicSubmissionInfoToSubmit.eventDate);
    eventDate.setHours(0, 0, 0, 0);
    return eventDate;
  }, [basicSubmissionInfoToSubmit.eventDate]);

  const handleHasReferralCheckbox = useCallback(
    (event) => {
      setHasReferral(event.target.checked ? "Checked" : "NotChecked");
    },
    [setHasReferral],
  );

  const handleCollaboratingPhysiotherapistCheckbox = useCallback(
    (event) => {
      setCollaboratingPhysiotherapist(
        event.target.checked ? "Checked" : "NotChecked",
      );
    },
    [setCollaboratingPhysiotherapist],
  );

  const validateHasReferralCheckbox = useCallback(
    (checked) => {
      if (checked !== "Checked" && checked !== true) {
        setHasRefferalError(invalidValueText);
        return false;
      } else {
        setHasRefferalError("");
        return true;
      }
    },
    [setHasRefferalError],
  );

  const validateNumberOfSessions = useCallback(
    (value) => {
      if (value !== "" && +value > 0) {
        setNumberOfSessionsError("");
        return true;
      }
      setNumberOfSessionsError(invalidValueText);
      return false;
    },
    [setNumberOfSessionsError],
  );

  const validateReceiptDate = useCallback(
    (value) => {
      if (
        value &&
        value.toString() !== "" &&
        value.toString() !== "Invalid Date"
      ) {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);
        if (
          dateValue.getTime() - currentDateValue.getTime() > 0 ||
          dateValue.getTime() - eventDateValue.getTime() < 0
        ) {
          setReceiptDateError(invalidValueText);
          return false;
        }
        const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(dateValue);
        const eventDateRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (eventDateRegex.test(dateValueStringFormatted)) {
          setReceiptDateError("");
          return true;
        }
        setReceiptDateError(invalidValueText);
        return false;
      }
      setReceiptDateError(invalidValueText);
      return false;
    },
    [currentDateValue, eventDateValue, setReceiptDateError],
  );

  const handleEventDateChange = useCallback(
    (value) => {
      if (validateReceiptDate(value)) {
        setReceiptDate(value);
      } else {
        setReceiptDate("");
      }
    },
    [validateReceiptDate],
  );

  const validateReceiptAmount = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptAmountError("");
        return true;
      }
      setReceiptAmountError(invalidValueText);
      return false;
    },
    [setReceiptAmountError],
  );

  const validateReceiptNumber = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptNumberError("");
        return true;
      }
      setReceiptNumberError(invalidValueText);
      return false;
    },
    [setReceiptNumberError],
  );

  const validateFiles = useCallback(
    (filesToCheck) => {
      let isValid = true;
      filesTypes.forEach((fileType) => {
        if (
          fileType.isMandatory &&
          (filesToCheck.length === 0 ||
            !filesToCheck?.find((file) => file.fileType === fileType.value))
        ) {
          isValid = false;
        }
      });
      setShowFileValidationError(!isValid);
      return isValid;
    },
    [filesTypes, setShowFileValidationError],
  );

  const validatePhysicalTherapyInfo = useCallback(() => {
    const isReferral = validateHasReferralCheckbox(hasReferral);
    const isNumberOfSessions = validateNumberOfSessions(numberOfSessions);
    const isReceiptDate = validateReceiptDate(receiptDate);
    const isReceiptAmount = validateReceiptAmount(receiptAmount);
    const isReceiptNumber = validateReceiptNumber(receiptNumber);
    const isFiles = validateFiles(files);

    return (
      isReferral &&
      isNumberOfSessions &&
      isReceiptDate &&
      isReceiptAmount &&
      isReceiptNumber &&
      isFiles
    );
  }, [
    validateHasReferralCheckbox,
    validateNumberOfSessions,
    validateReceiptDate,
    validateReceiptAmount,
    validateReceiptNumber,
    validateFiles,
    hasReferral,
    numberOfSessions,
    receiptDate,
    receiptAmount,
    receiptNumber,
    files,
  ]);

  const createNewPhysicalTherapyClaim = useCallback(() => {
    claimsStore.addClaimToSubmitingList({
      ExpenseType: ExpenseType.PhysicalTherapy,
      HasReferral: hasReferral === "Checked",
      CollaboratingPhysiotherapist: collaboratingPhysiotherapist === "Checked",
      NumberOfSessions: numberOfSessions,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      CollaboratingNetworkList: collaboratingPhysiotherapist === "Checked",
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
    });
  }, [
    claimsStore,
    hasReferral,
    collaboratingPhysiotherapist,
    numberOfSessions,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const saveEditedPhysicalTherapyClaim = useCallback(() => {
    claimsStore.saveEditedClaim({
      Id: props.selectedClaimToEdit.Id,
      ExpenseType: ExpenseType.PhysicalTherapy,
      HasReferral: hasReferral === "Checked",
      CollaboratingPhysiotherapist: collaboratingPhysiotherapist === "Checked",
      NumberOfSessions: numberOfSessions,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      CollaboratingNetworkList: collaboratingPhysiotherapist === "Checked",
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
    });
  }, [
    claimsStore,
    props.selectedClaimToEdit?.Id,
    hasReferral,
    collaboratingPhysiotherapist,
    numberOfSessions,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const handleContinueBtn = useCallback(() => {
    if (validatePhysicalTherapyInfo()) {
      if (props.selectedClaimToEdit !== null) {
        saveEditedPhysicalTherapyClaim();
      } else {
        createNewPhysicalTherapyClaim();
      }
      props.nextStep();
    }
  }, [
    validatePhysicalTherapyInfo,
    createNewPhysicalTherapyClaim,
    saveEditedPhysicalTherapyClaim,
    props,
  ]);

  const handleCancelBtn = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      claimsStore.clearSelectedClaimToEdit();
      props.nextStep();
    } else {
      props.previousStep();
    }
  }, [props, claimsStore]);

  const renderReceiptAmount = useCallback(() => {
    if (receiptAmountError === "") {
      return (
        <AmountInput
          labelText={t("claim.amount-payable")}
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
        />
      );
    } else {
      return (
        <AmountInput
          labelText={t("claim.amount-payable")}
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
          validationText={receiptAmountError}
        />
      );
    }
  }, [
    receiptAmount,
    receiptAmountError,
    setReceiptAmount,
    validateReceiptAmount,
  ]);

  const populateStateWithSelectedClaimToEdit = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      setHasReferral(
        props.selectedClaimToEdit.HasReferral ? "Checked" : "NotChecked",
      );
      setCollaboratingPhysiotherapist(
        props.selectedClaimToEdit.CollaboratingPhysiotherapist
          ? "Checked"
          : "NotChecked",
      );
      setNumberOfSessions(props.selectedClaimToEdit.NumberOfSessions);
      setReceiptDate(props.selectedClaimToEdit.ReceiptDate);
      setReceiptAmount(props.selectedClaimToEdit.ReceiptAmount);
      setOtherInsurerContributionAmount(
        props.selectedClaimToEdit.OtherInsurerContributionAmount,
      );
      setReceiptNumber(props.selectedClaimToEdit.ReceiptNumber);
      setFiles(props.selectedClaimToEdit.Files);
    }
  }, [
    props.selectedClaimToEdit,
    setHasReferral,
    setCollaboratingPhysiotherapist,
    setNumberOfSessions,
    setReceiptDate,
    setReceiptAmount,
    setOtherInsurerContributionAmount,
    setReceiptNumber,
    setFiles,
  ]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const showCollaboratingPhysiotherapistsCheckBox = useMemo(() => {
    return expenseTypes.some(
      (et) => et.Name === ExpenseType.PhysiotherapyWithAssociatePhysiotherapist,
    );
  }, [expenseTypes]);

  useEffect(() => {
    populateStateWithSelectedClaimToEdit();
  }, [populateStateWithSelectedClaimToEdit]);

  useEffect(() => {
    const options = [];

    const expenseType = expenseTypes.find(
      (et) => et.Name === ExpenseType.PhysicalTherapy,
    );

    if (expenseType) {
      expenseType.DocumentTypes?.forEach((dt) => {
        const currentDocumentType = documentTypes?.find(
          (sdt) => sdt.Name === dt.Name,
        );
        if (currentDocumentType) {
          options.push({
            text: currentDocumentType.Title,
            value: dt.Name,
            isMandatory: dt.IsRequired,
          });
        }
      });
    }

    setFilesTypes(options);
  }, [documentTypes, expenseTypes, setFilesTypes]);

  return (
    <div>
      <div
        className={`${styles.checkBoxContainer} ${styles.responsiveFontSize}`}
      >
        <Checkbox
          variant="Small"
          labelText={t("claim.pharmacy.referral")}
          name="HasReferral"
          onChange={(event) => {
            handleHasReferralCheckbox(event);
            validateHasReferralCheckbox(event.target.checked);
          }}
          dataState={hasReferral}
          mt="30px"
        />
        {hasRefferalError ? (
          <InlineMessage mt="6" text={hasRefferalError} />
        ) : (
          <></>
        )}
        {showCollaboratingPhysiotherapistsCheckBox ? (
          <Checkbox
            variant="Small"
            labelText={t("claim.therapy.belong-affiliated-network")}
            name="CollaboratingPhysiotherapist"
            onChange={(event) => {
              handleCollaboratingPhysiotherapistCheckbox(event);
            }}
            dataState={collaboratingPhysiotherapist}
            mt="30px"
          />
        ) : (
          <></>
        )}
      </div>
      <div className={styles.formRowContainer} style={{ marginTop: "30px" }}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <DatepickerInput
            defaultView="Day"
            labelText={t("claim.table.header.receipt-invoice-date")}
            name="ReceiptDate"
            placeholderText="ΗΗ/ΜΜ/ΕΕΕΕ"
            locale="elGR"
            inputFormat="dd/MM/yyyy"
            maxDate={currentDateValue}
            minDate={eventDateValue}
            views={[
              {
                name: "Year",
              },
              {
                name: "Month",
              },
              {
                name: "Day",
              },
            ]}
            onBlur={(event) => {
              let value = new Date(moment(event.target.value, "DD/MM/YYYY"));
              handleEventDateChange(value);
            }}
            onAccept={(value) => {
              handleEventDateChange(value);
            }}
            value={receiptDate}
            validationText={receiptDateError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <NumberInput
            labelText={`${t("claim.therapy.sessions-number")}*`}
            name="NumberOfSessions"
            onChange={(event) => {
              setNumberOfSessions(event.target.value);
              validateNumberOfSessions(event.target.value);
            }}
            placeholderText={t("claim.therapy.sessions-number")}
            value={numberOfSessions}
            validationText={numberOfSessionsError}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          {renderReceiptAmount()}
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <AmountInput
            labelText={t("claim.amount-other-insurance")}
            name="otherInsurerContributionAmount"
            popoverElement={
              <Popover
                headingText=" "
                placement="bottom-start"
                testId="popover"
                variant="Icon"
              >
                <div>
                  <Paragraph>{t("claim.examination.other-company")}</Paragraph>
                </div>
              </Popover>
            }
            onChange={(event) => {
              setOtherInsurerContributionAmount(event.target.value);
            }}
            value={otherInsurerContributionAmount}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <TextInput
            labelText={t("claim.medical.number-receipt-invoice")}
            name="ReceiptNumber"
            onChange={(event) => {
              setReceiptNumber(event.target.value);
              validateReceiptNumber(event.target.value);
            }}
            value={receiptNumber}
            validationText={receiptNumberError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`} />
      </div>
      {filesTypes.length > 0 ? (
        <FilesUpload
          filesTypes={filesTypes}
          files={files}
          addFile={addFile}
          removeFile={removeFile}
          showFileValidationError={showFileValidationError}
        />
      ) : (
        <></>
      )}
      <div className={`${styles.buttonsRow}`} style={{ marginTop: "20px" }}>
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleCancelBtn();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div className={styles.nextStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleContinueBtn();
            }}
          >
            {t("global.entry")}
          </Button>
        </div>
      </div>
      <div className={styles.compulsoryNote}>
        <Label text={t("claim.submission.required-fields")} />
      </div>
    </div>
  );
};

export default observer(PhysicalTherapy);
