import axios from "axios";

let baseURL = "NO URL";
if (process.env.REACT_APP_ENVIRONMENT === "local") {
  baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
}
if (process.env.REACT_APP_ENVIRONMENT === "debug") {
  baseURL = process.env.REACT_APP_BASE_URL_LOCAL_DEBUG;
}
if (process.env.REACT_APP_ENVIRONMENT === "dev") {
  baseURL = process.env.REACT_APP_BASE_URL_DEV;
}
if (process.env.REACT_APP_ENVIRONMENT === "test") {
  baseURL = process.env.REACT_APP_BASE_URL_TEST;
}
if (process.env.REACT_APP_ENVIRONMENT === "assesment") {
  baseURL = process.env.REACT_APP_BASE_URL_ASSESMENT;
}
if (process.env.REACT_APP_ENVIRONMENT === "live") {
  baseURL = process.env.REACT_APP_BASE_URL_LIVE;
}
if (process.env.REACT_APP_ENVIRONMENT === "uat") {
  baseURL = process.env.REACT_APP_BASE_URL_UAT;
}

function config() {
  const token = sessionStorage.getItem("msal.idtoken");
  const client = sessionStorage.getItem("client");
  return {
    headers: { Authorization: `Bearer ${token}`, ImpersonatedUser: client },
  };
}

function formDataConfig() {
  let newConfig = config();
  newConfig.headers["Content-Type"] = "multipart/form-data";
  return newConfig;
}
function JSONDataConfig() {
  let newConfig = config();
  newConfig.headers["Content-Type"] = "application/json";
  return newConfig;
}

//axios properties
axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(undefined, (error) => {
  const { status, data, config } = error.response;
  if (status === 401) {
    window.location.href = "/errorPages/logout";
  }
  throw error.response;
});

const responseBody = (response) => response.data;
const responseBodyFull = (response) => response;

const requests = {
  get: (url) => axios.get(url, config()).then(responseBody),
  getWithoutConfig: (url) => axios.get(url).then(responseBody),
  post: (url, body) => axios.post(url, body, config()).then(responseBody),
  patch: (url) => axios.patch(url, config()).then(responseBody),
  put: (url, body) => axios.put(url, body, config()).then(responseBody),

  getWithFullResponse: (url) => axios.get(url, config()).then(responseBodyFull),
  patchWithStatus: (url) => axios.patch(url, config()).then(responseBodyFull),
  putWithFullResponse: (url, body) =>
    axios.put(url, body, config()).then(responseBodyFull),
  postFormData: (url, body) =>
    axios.post(url, body, formDataConfig()).then(responseBody),
  postEncryptedDataJSON: (url, body) =>
    axios.post(url, body, JSONDataConfig()).then(responseBody),
};

const navBar = {
  list: () => requests.get("/widgets/mainmenu"),
};

const headerTheme = {
  listTheme: () => requests.get("/pages/page?aliasPath=/myContracts"),
  getbyUrl: (url) => requests.get(`/pages/page?aliasPath=${url}`),
};

const contracts = {
  list: () => requests.get(`/contracts`),
  bankAccountDetails: (applicationNumber) =>
    requests.get(
      `contracts/getcontractinfofrom400?applicationnumber=${applicationNumber}`,
    ),
};

const survey = {
  getSurvey: ({
    productCode,
    surveyNotificationDescription,
    contractNumber,
    documentId,
  }) =>
    requests.get(
      `/survey/getsurvey?productCode=${productCode}&surveyNotificationDescription=${surveyNotificationDescription}&contractNumber=${contractNumber}&documentId=${documentId}`,
    ),
};

const payments = {
  list: () => requests.get(`/payments/CustomerPayments`),
};

const documents = {
  contractlist: () => requests.get(`/documents/CustomerContractDocuments`),
  customerList: () => requests.get(`/documents/CustomerDocuments`),
};

const account = {
  person: () => requests.get(`account/individual`),
  legal: () => requests.get(`account/company`),
  entityDetails: () => requests.get(`account/details`),
  logLogin: () => requests.get(`account/loglogin`),
  logout: () => requests.post(`account/logout`),

  updateTermPages: (body) =>
    requests.putWithFullResponse(
      `account/update/preferredcommunicationmethod`,
      body,
    ),
  updateConsent: (body) =>
    requests.putWithFullResponse(`account/update/communicationconsent`, body),
};

const article = {
  list: () => requests.get("articles"),
};

const user = {
  registerForAll: (urlType, user) =>
    requests.post(`/account/register/${urlType}`, user),
  resendRegistrationEmail: (url) => requests.get(`/account/action?q=${url}`),
  impersonateUserGuid: (userEmail) =>
    requests.get(`/users/impersonate?userName=${userEmail}`),
  registerPartner: (urlType, user) =>
    requests.post(`/account/register/${urlType}`, user),
};

const chat = {
  request: (reqVal) => requests.post(`chat/CallMeBack`, reqVal),
};

const unregisterDownload = {
  successContract: (urlId) =>
    requests.patchWithStatus(`documents/unregistered?documentKey=${urlId}`),
  getUnregisteredDocsAndSurvey: (token) =>
    requests.get(`/documents/unregisteredandsurvey?documentKey=${token}`),

  submitSurvey: (body) => requests.post("/survey/answeredsurvey", body),

  getUnauthorizedContractSurvey: (token) =>
    requests.get(`/survey/getsurveyunauthorized?documentKey=${token}`),
};

const inbox = {
  messages: () => requests.get(`Messages/CustomerMessages`),
  messageDetails: (aliasPath) =>
    requests.get(`Messages/Message?aliasPath=${aliasPath}`),
  isReadStatus: (body) => requests.post(`Action/Set`, body),
  registerNonBulk: () => requests.post(`messages/nonbulk`),
};

const cookies = {
  getCookies: () => requests.get(`users/settings`),
  updateCookies: (body) =>
    requests.putWithFullResponse(`users/settings/cookies`, body),
  updateWelcomePopUp: (body) =>
    requests.put(`users/settings/promoarticle`, body),
};

const claims = {
  getMyClaims: () => requests.get(`claims`),
  getDiseases: (keyword) =>
    requests.get(`claims/diseases?searchKeywords=${keyword}`),
  getGroupPoliciesWithEligibilitySubmission: () =>
    requests.get(`claims/policies/submissioneligibility`),
  getPolicyBenefits: (policyId, referenceDate) =>
    requests.get(
      `claims/policybenefits?policyId=${policyId}&referenceDate=${referenceDate}`,
    ),
  getDiagnosticCenters: (policyId, referenceDate) =>
    requests.get(
      `claims/diagnosticCenters?policyId=${policyId}&referenceDate=${referenceDate}`,
    ),
  getDocumentTypes: () => requests.get(`claims/documenttypes`),
  getDoctorSpecialities: () => requests.get(`claims/doctorspecialities`),
  submitClaims: (body) => requests.post(`claims/submitclaims`, body),
  fileupload: (body) => requests.postFormData(`claims/claimfileupload`, body),
  resubmitClaimFiles: (body) =>
    requests.post(`claims/resubmitclaimfiles`, body),
};

const captcha = {
  validate: (captchaValidationRequest) =>
    requests.post(`/account/register/captcha`, captchaValidationRequest),
};

const settings = {
  getPublicSettings: () =>
    requests.getWithoutConfig("application/settings/public"),
  getPrivateSettings: () => requests.get("application/settings/private"),
};

const specialPages = {
  getSpecialPage: (url) => requests.get(`/pages/PublicPage?aliasPath=${url}`),
};
const infoPages = {
  getInfoPage: (url) => requests.get(`/pages/Page?aliasPath=${url}`),
  getInfoPublicPage: (url) =>
    requests.get(`/pages/PublicPage?aliasPath=${url}`),
};

const consentPages = {
  getConsentPage: (url) => requests.get(`/pages/Page?aliasPath=${url}`),
  getConsentPublicPage: (url) =>
    requests.get(`/pages/PublicPage?aliasPath=${url}`),
};

const eClaimsTermsAndConditionsPages = {
  getEClaimsTermsAndConditionsPage: (url) =>
    requests.get(`/pages/Page?aliasPath=${url}`),
};

const contractCoverages = {
  getCoverage: (contractId, productCode, productCategoryId) =>
    requests.get(
      `/contract/coverages?ContractNumber=${contractId}&productCode=${productCode}&productCategoryId=${productCategoryId}`,
    ),
};

const contractBill = {
  getBill: (contractId, productCode, productCategoryId) =>
    requests.get(
      `/contract/bill?ContractNumber=${contractId}&productCode=${productCode}&productCategoryId=${productCategoryId}`,
    ),
};

const contractAgent = {
  getAgent: (contractId) =>
    requests.get(`/contract/agent?ContractNumber=${contractId}`),
};

const preview = {
  getPreviewElement: (urlKey) =>
    requests.get(`/pages/pagepreview?encryptedParams=${urlKey}`),
};

const onlinePayments = {
  ticketingWebService: (body) =>
    requests.post(`payments/onlinepayment/transaction`, body),
  updateStatusTransaction: (body) =>
    requests.post(`payments/onlinepayment/validations/transaction`, body),
  ticketingWebServiceCreditCard: (body) =>
    requests.post(`payments/onlinepayment/changecreditcard`, body),
};
const hr = {
  getDocumentsListHR: (companyId, policyNumber) =>
    requests.get(
      `/documents/hrcontractdocuments?companyid=${companyId}&contractnumber=${policyNumber}`,
    ),
  getContractListHR: (body) =>
    requests.post(`/contracts/GetHrBrokerContracts`, body),
  getPaymentsHR: (body) =>
    requests.post(`/payments/GetHrInvoicesContracts`, body),
  getContractCoveragesHR: (policy) =>
    requests.get(
      `/contract/coverages/CoveragesPerOwner?contractNumber=${policy}`,
    ),
  getClaimsHR: (contractNumber, [dateFrom, dateTo], employeeNo) =>
    requests.get(
      `/claims/hrclaims?contractnumber=${contractNumber}&datefrom=${dateFrom}&dateto=${dateTo}${employeeNo ? "&payrollcode=" : ""}${
        employeeNo ? employeeNo : ""
      }`,
    ),
  getPolicycPayemntsHR: ({ contractNumber, invoiceNumber, invoiceType }) =>
    requests.get(
      `/documents/PolicyDocuments?ContractNumber=${contractNumber}&InvoiceNumber=${invoiceNumber}&InvoiceType=${invoiceType}`,
    ),
  getInsuredMembers: (policy) =>
    requests.get(
      `/grouppensioncontract/insuredmembers?contractnumber=${policy}`,
    ),
  getGroupPensionPayments: (policy) =>
    requests.get(`/grouppensioncontract/hrreceipts?contractnumber=${policy}`),
  getGroupPensionAccountBalance: (policy) =>
    requests.get(
      `/grouppensioncontract/hrbillanalysis?contractnumber=${policy}`,
    ),
};

const kyc = {
  getKycUrl: (hasKycAllParams) =>
    requests.get(
      `oath/kyc/GenericUrlForRedirectingGov?hasKycAllParams=${hasKycAllParams}`,
    ),
  sendResponseFromKyc: (body) =>
    requests.post(`oath/kyc/ResponseHandlrer`, body),
  sendTermAcceptanceKyc: (body) =>
    requests.post(`oath/kyc/termacceptance`, body),
};

const contractGroupPension = {
  getBillAnalysis: (contractId) =>
    requests.get(
      `/grouppensioncontract/billanalysis?contractNumber=${contractId}`,
    ),
  getCertificates: (contractId) =>
    requests.get(
      `/grouppensioncontract/certificates?contractNumber=${contractId}`,
    ),
};

const portfolio = {
  getUserPortfolio: () =>
    requests.get(`/funds/GetCurrentBankPortfolioFundsPerCustomer`),
  getFundPrices: (fundCode) =>
    requests.get(`funds/GetFundPrices?FundCode=${fundCode}`),
};
const adHocDocumentDownload = {
  getAdHocDocumentDownload: (url, body) =>
    requests.postEncryptedDataJSON(`${url}`, body),
};

const api = {
  preview,
  contractAgent,
  contractBill,
  contractCoverages,
  onlinePayments,
  infoPages,
  consentPages,
  specialPages,
  settings,
  captcha,
  cookies,
  claims,
  unregisterDownload,
  chat,
  user,
  article,
  account,
  documents,
  payments,
  contracts,
  navBar,
  headerTheme,
  inbox,
  hr,
  eClaimsTermsAndConditionsPages,
  kyc,
  contractGroupPension,
  portfolio,
  survey,
  adHocDocumentDownload,
};

export default api;
