import React, { useCallback, useContext } from "react";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  createContainer,
} from "victory";
import {
  Heading,
  Modal,
  Icon,
  Label,
  ToggleButtonGroup,
} from "@nn-design-system/react-component-library";
import moment from "moment";
import "moment/locale/el";
import styles from "../../Css/GroupPension/GroupPension.module.scss";
import SettingsStore from "../../Stores/SettingsStore";
import { min } from "moment/moment";

const charHeight = 350;
const chartAdjustmentFactor = charHeight / 325;

const yAxisLabelFormatter = (tick, index, ticks) => {
  const nf = new Intl.NumberFormat("el-GR", {
    style: "currency",
    currency: "EUR",
  });
  return nf.format(tick);
};

const formatToolTipSharePrice = (value) => {
  const nf = new Intl.NumberFormat("el-GR");
  return nf.format(value);
};

const findMinimumDisplayXDomainValue = (priceHistoryData) => {
  if (priceHistoryData) {
    let allDates = priceHistoryData.map(
      (elem) => new Date(elem.SharePriceDate),
    );
    return new Date(Math.min.apply(null, allDates));
  }
  return null;
};

const findMaximumDisplayXDomainValue = (priceHistoryData) => {
  if (priceHistoryData) {
    let allDates = priceHistoryData.map(
      (elem) => new Date(elem.SharePriceDate),
    );
    return new Date(Math.max.apply(null, allDates));
  }
  return null;
};

const findMaximumDisplayYDomainValue = (priceHistoryData) => {
  if (priceHistoryData) {
    let allValues = priceHistoryData.map((elem) => elem.SharePrice);
    const minValue = Math.min.apply(null, allValues);
    const maxValue = Math.max.apply(null, allValues);
    const minMaxRange = maxValue - minValue;
    return minValue + minMaxRange * chartAdjustmentFactor; //Required calculation to avoid clipping of the highest value in the chart container using interpolation="catmullRom"
  }
  return null;
};

const findMinimumDisplayYDomainValue = (priceHistoryData) => {
  if (priceHistoryData) {
    let allValues = priceHistoryData.map((elem) => elem.SharePrice);
    const minValue = Math.min.apply(null, allValues);
    const maxValue = Math.max.apply(null, allValues);
    const minMaxRange = maxValue - minValue;
    return minValue - minMaxRange * (chartAdjustmentFactor - 1); //Required calculation to avoid clipping of the lowest value in the chart container using interpolation="catmullRom"
  }
  return null;
};

const formatInputData = (priceHistoryData) => {
  if (priceHistoryData) {
    return priceHistoryData.map((elem) => {
      return {
        SharePrice: elem.SharePrice,
        SharePriceDate: new Date(elem.SharePriceDate),
      };
    });
  }
  return null;
};

const filterDataByMinMaxDates = (data, minimumDate, maximumDate) => {
  let filteredData = data.filter((item) => {
    let itemDate = new Date(item.SharePriceDate);
    return itemDate >= minimumDate && itemDate <= maximumDate;
  });

  return filteredData;
};

const monthDiff = (moment1, moment2) => {
  const start = moment(moment1);
  const end = moment(moment2);

  const monthDiff = end.diff(start, "months");

  return monthDiff;
};

const determineXFormatValue = (minimumDate, maximumDate) => {
  const dateDiffInMonths = monthDiff(minimumDate, maximumDate);

  if (dateDiffInMonths > 5) return "MMM 'YY";

  return "DD MMM";
};

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

class GroupPensionZoomLine extends React.Component {
  constructor(props) {
    super();
    const minimumDisplayXDomainValue = findMinimumDisplayXDomainValue(
      props.data,
    );
    const maximumDisplayXDomainValue = findMaximumDisplayXDomainValue(
      props.data,
    );
    this.state = {
      xFormatValue: determineXFormatValue(
        minimumDisplayXDomainValue,
        maximumDisplayXDomainValue,
      ),
      zoomValue: "all",
      zoomDomain: {
        x: [minimumDisplayXDomainValue, maximumDisplayXDomainValue],
      },
      yDomainMinimum: findMinimumDisplayYDomainValue(props.data),
      yDomainMaximum: findMaximumDisplayYDomainValue(props.data),
      displayData: props.data,
      xtickCount: 6,
    };
  }

  handleZoom(domain) {
    this.setState({
      zoomDomain: domain,
      xFormatValue: determineXFormatValue(domain.x[0], domain.x[1]),
    });
  }

  handleZoomValue(value) {
    let minimumXValue = findMinimumDisplayXDomainValue(this.props.data);
    const maxXZoomedValue = findMaximumDisplayXDomainValue(this.props.data);
    let xnewFormatValue = determineXFormatValue(minimumXValue, maxXZoomedValue);
    let xnewTickCount = 6;
    let minimumValueYDomain = findMinimumDisplayYDomainValue(this.props.data);
    let maximumValueYDomain = findMaximumDisplayYDomainValue(this.props.data);
    let newDisplayData = this.props.data;

    if (value === "week") {
      minimumXValue = moment(maxXZoomedValue).subtract(7, "d");
      newDisplayData = filterDataByMinMaxDates(
        this.props.data,
        minimumXValue,
        maxXZoomedValue,
      );
      minimumValueYDomain = findMinimumDisplayYDomainValue(newDisplayData);
      maximumValueYDomain = findMaximumDisplayYDomainValue(newDisplayData);
      xnewFormatValue = "DD MMM";
    }
    if (value === "month") {
      minimumXValue = moment(maxXZoomedValue).subtract(1, "M");
      newDisplayData = filterDataByMinMaxDates(
        this.props.data,
        minimumXValue,
        maxXZoomedValue,
      );
      minimumValueYDomain = findMinimumDisplayYDomainValue(newDisplayData);
      maximumValueYDomain = findMaximumDisplayYDomainValue(newDisplayData);
      xnewFormatValue = "DD MMM";
    }
    if (value === "6months") {
      minimumXValue = moment.max(
        moment(minimumXValue),
        moment(maxXZoomedValue).subtract(6, "M"),
      );
      newDisplayData = filterDataByMinMaxDates(
        this.props.data,
        minimumXValue,
        maxXZoomedValue,
      );
      minimumValueYDomain = findMinimumDisplayYDomainValue(newDisplayData);
      maximumValueYDomain = findMaximumDisplayYDomainValue(newDisplayData);
      xnewFormatValue = determineXFormatValue(minimumXValue, maxXZoomedValue);
      xnewTickCount = 4;
    }
    if (value === "year") {
      minimumXValue = moment.max(
        moment(minimumXValue),
        moment(maxXZoomedValue).subtract(1, "Y"),
      );
      newDisplayData = filterDataByMinMaxDates(
        this.props.data,
        minimumXValue,
        maxXZoomedValue,
      );
      minimumValueYDomain = findMinimumDisplayYDomainValue(newDisplayData);
      maximumValueYDomain = findMaximumDisplayYDomainValue(newDisplayData);
      xnewFormatValue = determineXFormatValue(minimumXValue, maxXZoomedValue);
    }

    this.setState({
      xFormatValue: xnewFormatValue,
      zoomValue: value,
      zoomDomain: { x: [minimumXValue, maxXZoomedValue] },
      yDomainMinimum: minimumValueYDomain,
      yDomainMaximum: maximumValueYDomain,
      displayData: newDisplayData,
      xTickCount: xnewTickCount,
    });
  }

  render() {
    return (
      <div>
        {this.state.displayData && this.state.displayData.length > 0 && (
          <>
            <div>
              <ToggleButtonGroup
                labelText=" "
                onChange={(event, value) => this.handleZoomValue(value)}
                toggleButtons={[
                  {
                    text: "1 εβδομάδα",
                    value: "week",
                  },
                  {
                    text: "1 μήνας",
                    value: "month",
                  },
                  {
                    text: "6 μήνες",
                    value: "6months",
                  },
                  {
                    text: "1 έτος",
                    value: "year",
                  },
                  {
                    text: "Όλες",
                    value: "all",
                  },
                ]}
                value={this.state.zoomValue}
                variant="Horizontal"
              />
            </div>
            <VictoryChart
              width={600}
              height={charHeight}
              padding={{ left: 65, top: 50, right: 65, bottom: 50 }}
              scale={{ x: "linear" }}
              containerComponent={
                <VictoryZoomVoronoiContainer
                  zoomDomain={this.state.zoomDomain}
                  onZoomDomainChange={this.handleZoom.bind(this)}
                  labels={({ datum }) =>
                    `${moment(datum.SharePriceDate).format(
                      "DD MMM YY",
                    )}:\n${formatToolTipSharePrice(parseFloat(datum.SharePrice).toFixed(2))}`
                  }
                />
              }
            >
              <VictoryAxis
                crossAxis
                tickCount={this.state.xTickCount}
                tickFormat={(tick) => {
                  return moment(tick)
                    .locale("el")
                    .format(this.state.xFormatValue);
                }}
              />

              <VictoryAxis
                dependentAxis
                domain={[this.state.yDomainMinimum, this.state.yDomainMaximum]}
                tickFormat={yAxisLabelFormatter}
                style={{ tickLabels: { padding: 5 } }}
              />
              <VictoryLine
                interpolation="catmullRom"
                style={{
                  data: { stroke: "#EA650D" },
                }}
                data={formatInputData(this.state.displayData)}
                x="SharePriceDate"
                y="SharePrice"
              />
            </VictoryChart>
            <div className={styles.note}>
              <Label text={this.props.noteMessage} className />
              <Icon
                name="Information"
                variant="Small"
                color="#EA650D"
                mr="5px"
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const GroupPensionPriceHistoryModal = (props) => {
  const { getResourceStringValue } = useContext(SettingsStore);
  return (
    <Modal
      height="600px"
      width="690px"
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
    >
      <div className={styles.analysisPriceHistoryModal}>
        <div></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            height: "50px",
          }}
        >
          <button
            className={styles.noOutline}
            style={{
              backgroundColor: "unset",
              margin: "0",
              border: "0",
              padding: "0",
              cursor: "pointer",
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              props.onClose();
            }}
          >
            <Icon name="Cross" variant="Medium" color="#EA650D" />
          </button>
        </div>
        <div className={styles.header}>
          <Heading isFirstSection>ΙΣΤΟΡΙΚΟΤΗΤΑ ΤΙΜΩΝ</Heading>
        </div>
        <div className={styles.content}>
          {props.data && (
            <GroupPensionZoomLine
              data={props.data}
              noteMessage={getResourceStringValue(
                "NN.Contract.Analysis.ValueHistoryZoomNote",
              )}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GroupPensionPriceHistoryModal;
