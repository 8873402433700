import React, { useState, useContext } from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import agent from "../../Api/agent";
import { setDateFormatForDateTime } from "../CommonComp/Functions";
import ElementDownload from "./ElementDownload";
import { SurveyModal } from "../Survey/SurveyModal";
import AccountStore from "../../Stores/AccountStore";

export const DocumentsTableNN = ({ documentList }) => {
  const { t } = useTranslation();
  const { accountData } = useContext(AccountStore);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);
  const [surveyContents, setSurveyContents] = useState();

  async function getSurvey(params) {
    try {
      const survey = await agent.survey.getSurvey(params);

      if (!survey) {
        return;
      }

      setSurveyContents({
        encUnregisteredDownloadModel: {
          ContractNumber: params.contractNumber,
          CustomerID: accountData.AccountId,
          NotificationTypeDescription: params.surveyNotificationDescription,
        },
        survey,
      });
      setIsSurveyModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  }

  function handleCloseModal(_event, reason) {
    if (["clickButton", "escapeKeyDown"].includes(reason)) {
      setIsSurveyModalOpen(false);
    }
  }

  const columns = [
    {
      field: "a",
      headerName: t("file-upload.document-type"),
      flex: 1.5,
      headerAlign: "left",
      align: "left",
      sortable: true,
      renderCell: ({ value }) => {
        return <span>{value}</span>;
      },
    },
    {
      field: "b",
      headerName: t("document.date-issue"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {params.value ? setDateFormatForDateTime(params.value) : "-"}
          </span>
        );
      },
    },
    {
      field: "c",
      headerName: t("document.download"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        const { DocumentLink, DocumentType, getSurveyParams } = value;
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ElementDownload
              DocumentLink={DocumentLink}
              fileName={DocumentType}
              onClick={
                getSurveyParams ? () => getSurvey(getSurveyParams) : undefined
              }
            />
          </div>
        );
      },
    },
  ];

  const rows = documentList?.map((elemDoc, i) => {
    const {
      DocumentType,
      IssueDate,
      DocumentLink,
      DocumentNotificationInfo,
      ContractNumber,
      DocumentId,
    } = elemDoc;
    return {
      id: i,
      a: DocumentType,
      b: IssueDate,
      c: {
        DocumentLink: DocumentLink,
        DocumentType: DocumentType,
        getSurveyParams: DocumentNotificationInfo
          ? {
              productCode: DocumentNotificationInfo.ProductCode,
              surveyNotificationDescription:
                DocumentNotificationInfo.NotificationType,
              contractNumber: ContractNumber,
              documentId: String(DocumentId),
            }
          : undefined,
      },
    };
  });

  return (
    <>
      <Table
        localeText={{ noRowsLabel: t("global.no-results-found") }}
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
      />

      <SurveyModal
        surveyContents={surveyContents}
        isOpen={isSurveyModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};
