import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ContractExtraBenefitRow from "./ContractExtraBenefitRow";
import ContractSelectedCoverageRow from "./ContractSelectedCoverageRow";
import "../../Css/Contracts_Details/selected-contract-coverages.css";
import ContractStore from "../../Stores/ContractStore";
import {
  setDateFormatForDateTime,
  ConvertDecimalToString,
} from "../CommonComp/Functions";
useTranslation;
const ContractSelectedCoverage = (props) => {
  const { t } = useTranslation();
  const contractStore = useContext(ContractStore);
  const [insured, setInsured] = useState();

  useEffect(() => {
    if (props.contractId && props.getData) {
      contractStore.loadCoverages(
        props.contractId,
        props.ProductCode,
        props.productCategoryId,
      );
    }
  }, [props.contractId, props.getData]);

  const columns = [
    {
      field: "CoverageDescription",
      headerName: t("contract.coverage-name"),
      flex: 1.7,
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      sortable: false,
    },
    {
      field: "fullName",
      headerName: t("contract.secured"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1.7,
      sortable: false,
    },
    props.contractType !== "investment" && {
      field: "Limit",
      headerName: t("contract.coverage-limit"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => ConvertDecimalToString(props.value),
    },
    {
      field: "NetPremium",
      headerName: t("contract.net-insurance"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => ConvertDecimalToString(props.value),
    },
    {
      field: "StartDate",
      headerName: t("contract.start-date"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => setDateFormatForDateTime(props.value),
    },
    {
      field: "ExpiredDate",
      headerName: t("global.expiry-date"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => setDateFormatForDateTime(props.value),
    },
  ].filter(Boolean);

  const rows = useMemo(() => {
    let data =
      props.Coverages &&
      props.Insureds &&
      props.Coverages.map((element, i) => {
        const insuranceHolderData =
          props.Insureds.find((c) => c.ClientId === element.ClientId) ??
          props.PolicyHolder;
        setInsured({ ...insuranceHolderData });
        if (
          props.Insureds.find((c) => c.ClientId === element.ClientId) ||
          (props.UserId === props.PolicyHolder.ClientId &&
            props.PolicyHolder.ClientId === element.ClientId)
        )
          return {
            id: i,
            fullName: `${insuranceHolderData.Surname} ${insuranceHolderData.FirstName}`,
            ...element,
          };
      }).filter(Boolean);
    return data || [];
  }, [props]);

  return (
    <Fragment>
      {props.showCoverages ? (
        <>
          {props.ExtraBenefit
            ? props.ExtraBenefit.map((extraBenefitElement, i) => {
                return (
                  <ContractExtraBenefitRow
                    key={i}
                    ExtraBenefit={extraBenefitElement}
                  />
                );
              })
            : null}
          <Table
            localeText={{ noRowsLabel: t("global.no-results-found") }}
            mt="24px"
            rows={rows || []}
            density="comfortable"
            columns={columns}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            hasAutoHeight
            getRowHeight={() => "auto"}
            getDetailPanelContent={(rowData) => {
              return (
                <ContractSelectedCoverageRow
                  insured={insured}
                  Benefits={props.Benefits}
                  Clauses={props.Clauses}
                  contractType={props.contractType}
                  element={rowData.row}
                ></ContractSelectedCoverageRow>
              );
            }}
            getDetailPanelHeight={() => {
              return "auto";
            }}
            hideFooter
          ></Table>
        </>
      ) : null}
    </Fragment>
  );
};

export default observer(ContractSelectedCoverage);
