import React from "react";

import img from "../../assets/index";

const AccountRegLoginHeader = () => {
  return (
    <div className="logo-login">
      <div>
        <a href="https://www.nnhellas.gr/" className="logo">
          <img src={img.logo_NN} alt="nnhellas-logo" />
        </a>
      </div>
      <div className="myNN">
        <span>myNN</span>
      </div>
    </div>
  );
};

export default AccountRegLoginHeader;
